nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border: 2px solid rgb(153, 153, 153);
    border-radius: 999px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: all 0.3s ease;
    color: white;
    outline: 0;
    &:hover {
      background: rgb(225, 5, 233);
      color: white;

      transition: all 1s;
    }
  }
}
@media screen and (max-width: 768px) {
  nav {
    button {
      z-index: 10;
    }
  }
}
