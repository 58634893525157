.song-container {
  min-height: 60vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
   border-radius: 50%;
    height: 200px;
    width: 200px !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    padding: 3rem 1rem 1rem 1rem;
    color: white;
  }
  h3 {
    font-size: 1rem;
    color: white;
  }
}
@media screen and (max-width: 768px) {
  .song-container {
    img {
      width: 60%;
    }
  }
}
