.library {
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  height: 100%;
  background: lightcyan;
  box-shadow: 2px 2px 50px rgb(231, 228, 228);
  overflow: scroll;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  opacity: 0;

  h2 {
    padding-left: 2rem;
    color: white;
  }
}
.library-song {
  display: flex;
  align-items: center;

  padding: 1rem 2rem 1rem 2rem;
  cursor: pointer;
  transition: background 0.5s ease;
  img {
    width: 40%;
  }
  &:hover {
    background: lightblue;
  }
}
.song-description {
  h3 {
    font-size: 0.8rem;
    padding-left: 2rem;
    color: rgb(226, 226, 226);
  }
  h4 {
    font-size: 0.5rem;
    color: rgb(168, 164, 164);
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(139, 139, 139, 0.993) transparent;
}
*::-webkit-scrollbar {
  width: 5%;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-slider-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.selected {
  background: rgb(233, 200, 243);
}
.active-library {
  transform: translateX(0%);
  opacity: 1;
  background: transparent;
  backdrop-filter: blur(6px);
}
@media screen and (max-width: 768px) {
  .library {
    width: 100%;
  }
}
